var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "UserProfile" },
    [
      _c(
        "div",
        { staticClass: "company" },
        [
          _c("TransitionFade", [
            _vm.profile
              ? _c(
                  "div",
                  { staticClass: "image" },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "arrow",
                      attrs: { icon: ["far", "building"] }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]),
          _c("TransitionSlide", { attrs: { side: "left" } }, [
            _vm.profile
              ? _c("div", { staticClass: "content" }, [
                  _c("div", { staticClass: "main-content" }, [
                    _c("span", { staticClass: "company-name" }, [
                      _vm._v(_vm._s(_vm.profile.NAME))
                    ]),
                    _c("span", { staticClass: "nif" }, [
                      _vm._v(_vm._s(_vm.profile.NIF))
                    ]),
                    _c(
                      "div",
                      [
                        _c(
                          "BaseButton",
                          {
                            staticClass: "edit-profile-button",
                            attrs: { variation: "secondary", size: "s" },
                            on: { click: _vm.openModal }
                          },
                          [
                            _vm._v(" Editar "),
                            _c("font-awesome-icon", {
                              attrs: { icon: ["fas", "pen"] }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c("p", { staticClass: "dir" }, [
                    _vm._v(" " + _vm._s(_vm.profile.DIR) + " ")
                  ])
                ])
              : _vm._e()
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "info" },
        [
          _c("TransitionSlide", { attrs: { side: "bottom" } }, [
            _vm.profile
              ? _c(
                  "div",
                  { staticClass: "main" },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "coins-image",
                      attrs: { icon: ["fas", "coins"] }
                    }),
                    _c("div", { staticClass: "activity" }, [
                      _c("p", {
                        domProps: {
                          innerHTML: _vm._s(_vm.$t("QUESTION.ACT.LABEL") + ":")
                        }
                      }),
                      _c("p", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("QUESTION.ACT.CHOICE." + _vm.profile.ACT)
                          )
                        }
                      })
                    ]),
                    _c("UserProfileStatement", {
                      attrs: {
                        icon: ["fas", "caret-right"],
                        answer: _vm.$t("RESULT.EMP.LABEL", [
                          _vm.$t("QUESTION.PEMP.CHOICE." + _vm.profile.EMP)
                        ])
                      }
                    }),
                    _c("UserProfileStatement", {
                      attrs: {
                        icon: ["fas", "caret-right"],
                        answer: _vm.$t("RESULT.BUS.LABEL", [
                          _vm.$t("QUESTION.PBUS.CHOICE." + _vm.profile.BUS)
                        ])
                      }
                    }),
                    _c("UserProfileStatement", {
                      attrs: {
                        icon: ["fas", "caret-right"],
                        answer: _vm.$t("RESULT.BAL.LABEL", [
                          _vm.$t("QUESTION.PBAL.CHOICE." + _vm.profile.BAL)
                        ])
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]),
          _c("TransitionSlide", { attrs: { side: "bottom" } }, [
            _vm.profile
              ? _c(
                  "div",
                  { staticClass: "sustainability" },
                  [
                    _c("h2", { staticClass: "title" }, [
                      _vm._v(" Sostenibilidad ")
                    ]),
                    _c("font-awesome-icon", {
                      staticClass: "eco-image",
                      attrs: { icon: ["fas", "leaf"] }
                    }),
                    _vm.profile.SOS1 === 1
                      ? _c("UserProfileStatement", {
                          attrs: {
                            icon: ["fas", "check"],
                            answer: _vm.$tc(
                              "RESULT.SOS2.LABEL",
                              _vm.profile.SOS2,
                              [_vm.profile.SOS2]
                            )
                          }
                        })
                      : _vm._e(),
                    _vm.profile.SOS1 === 1
                      ? _c("UserProfileStatement", {
                          attrs: {
                            icon: ["fas", "check"],
                            answer: _vm.$t("RESULT.VOL.LABEL", [
                              _vm.$t("QUESTION.VOL.CHOICE." + _vm.profile.VOL)
                            ])
                          }
                        })
                      : _vm._e(),
                    _vm.profile.SOS1 === 1
                      ? _c("UserProfileStatement", {
                          attrs: {
                            icon: ["fas", "check"],
                            answer: _vm.$t("RESULT.INF.LABEL", [_vm.INFAnswer])
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      ),
      _vm.modalOpen
        ? _c(
            "BaseModal",
            {
              attrs: { size: "l", title: "Perfil", "header-visible": true },
              on: { close: _vm.closeModal }
            },
            [
              _c("UserProfileCreation", {
                attrs: { "user-id": _vm.user.id },
                on: { close: _vm.closeModal }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }