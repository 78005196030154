<template>
  <div class="Users">
    <div class="header">
      <TransitionFade>
        <div
          v-if="items"
          class="header-content"
        >
          <h1 class="title">
            Lista de clientes
          </h1>

          <BaseButton
            class="button-create"
            @click="createUser()"
          >
            Crear cliente
            <font-awesome-icon
              :icon="['fas', 'file-alt']"
            />
          </BaseButton>
        </div>
      </TransitionFade>
    </div>

    <div class="table">
      <TransitionFade>
        <BaseTable
          v-if="items"
          :headers="headers"
          :body="tableUsers"
        >
          <template
            v-for="(user, i) in items"
            v-slot:[`actions-${i}`]
          >
            <div
              :key="i"
              class="table-actions"
            >
              <BaseButton
                variation="secondary"
                size="s"
                @click="editUser(user.id)"
              >
                Editar
                <font-awesome-icon
                  size="xs"
                  :icon="['fas', 'pen']"
                />
              </BaseButton>

              <BaseButton
                size="s"
                @click="openUser(user.id)"
              >
                Abrir
                <font-awesome-icon
                  size="xs"
                  :icon="['fas', 'chevron-right']"
                />
              </BaseButton>
            </div>
          </template>
        </BaseTable>
      </TransitionFade>
    </div>

    <BaseModal
      v-if="modalOpen"
      size="l"
      title="Perfil de cliente"
      :header-visible="true"
      @close="closeModal"
    >
      <UserProfileCreation
        :user-id="userSelected ? userSelected.id : undefined"
        @close="closeModal"
      />
    </BaseModal>
  </div>
</template>

<script>

import { get } from 'vuex-pathify';

import UserProfileCreation from '@/components/users/UserProfileCreation';
import TransitionFade from '@/transitions/TransitionFade';

export default {
  components: {
    TransitionFade,
    UserProfileCreation,
  },

  props: {
    items: {
      type: Array,
      default: undefined,
    },
  },

  data() {
    return {
      headers: [
        ['NAME', 'NIF', 'DIR', 'REPORTS', 'ACTIONS'],
      ],
      modalOpen: false,
      userSelected: undefined,
    };
  },

  computed: {
    ...get('reportsModule', ['getClient']),

    tableUsers() {
      return this.items?.map(user => ({
        name: user.profile.NAME,
        nif: user.profile.NIF,
        dir: user.profile.DIR,
        reports: user.reports,
      }));
    },
  },

  methods: {
    closeModal() {
      this.userSelected = undefined;
      this.modalOpen = false;
    },

    createUser() {
      this.modalOpen = true;
    },

    async editUser(userId) {
      await this.$store.dispatch('reportsModule/fetchClient', userId);
      this.userSelected = this.getClient();
      // this.userSelected = this.getClient(userId);
      this.modalOpen = true;
    },

    openUser(userId) {
      this.$emit('open-user', userId);
    },
  },

};
</script>

<style lang="scss" scoped>
.Users{
  .header{
    &:empty{
      @include empty-content(30px);
    }
    margin-bottom: $spacing-m;

    .header-content{
      display: grid;
      grid-template-columns: 1fr auto;
      gap: $spacing-m;
      align-items: center;

      .title{
        font-weight: 500;
        text-transform: uppercase;
        font-size: $font-size-xl;
        padding-bottom: $spacing-xs;
        border-bottom: 1px solid;
      }

      .button-create{
        margin-left: auto;
      }
    }
  }

  .table:empty{
    @include empty-content(150px);
  }

  .table-actions{
    display: grid;
    justify-content: end;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    gap: $spacing-s;

    .BaseButton{
      min-width: 100px;
    }
  }
}
</style>
