<template>
  <div
    v-if="user && user.profile ===2"
    class="DashboardView"
  >
    <EmptySubHeader class="subheader" />

    <h1 class="title">
      {{ $t('SELECT.TITLE.PROGRAM') }}
    </h1>

    <div
      v-if="!isLoading"
      class="content"
    >
      <Programs />
    </div>
    <div v-else>
      <BaseSpinner />
    </div>

    <div class="logo-aztertu">
      <a
        href="https://www.euskadi.eus/informacion/programa-aztertu/web01-a2inghez/es/"
        target="_blank"
      >
        <img
          class="logo"
          src="@/assets/images/aztertu_completo.png"
        ></a>
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';

import EmptySubHeader from '@/components/subheader/EmptySubHeader';
import Programs from '@/components/home/Programs';

export default {
  components: {
    EmptySubHeader,
    Programs,
  },

  computed: {
    ...get('authModule', ['isLoading', 'getUser']),

    user() {
      return this.getUser();
    },
  },

  async mounted() {
    await this.$store.dispatch('authModule/fetchUser');
    if (this.user && this.user?.profile === 1) {
      this.$router.push({ name: 'PanelAdmin' });
    }
  },

};
</script>

<style lang="scss" scoped>
.DashboardView {

  .title{
    text-align: center;
    padding: 1rem;
    font-size: $font-size-2xl;
    font-weight: 500;

     @media #{$breakpoint-lg-max} {
      margin-top: 1rem;
      padding-top: 3rem;
    }
  }
 .content {
    max-width: 1200px;
    margin: 2rem auto;
    padding: 2rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    @media #{$breakpoint-lg-max} {
      margin: 0;
      padding: 0;
    }
  }


  @media #{$breakpoint-lg-max} {
    .subheader{
      display: none;
    }
  }

  .logo-aztertu{
    display: flex;
    justify-content: center;
    margin-top: -5rem;

    .logo{
      width: 28rem;
      cursor: pointer;

       @media #{$breakpoint-lg-max} {
         width: 19rem;
       }
    }
  }
}
</style>
