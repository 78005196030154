var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Users" },
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c("TransitionFade", [
            _vm.items
              ? _c(
                  "div",
                  { staticClass: "header-content" },
                  [
                    _c("h1", { staticClass: "title" }, [
                      _vm._v(" Lista de clientes ")
                    ]),
                    _c(
                      "BaseButton",
                      {
                        staticClass: "button-create",
                        on: {
                          click: function($event) {
                            return _vm.createUser()
                          }
                        }
                      },
                      [
                        _vm._v(" Crear cliente "),
                        _c("font-awesome-icon", {
                          attrs: { icon: ["fas", "file-alt"] }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table" },
        [
          _c(
            "TransitionFade",
            [
              _vm.items
                ? _c("BaseTable", {
                    attrs: { headers: _vm.headers, body: _vm.tableUsers },
                    scopedSlots: _vm._u(
                      [
                        _vm._l(_vm.items, function(user, i) {
                          return {
                            key: "actions-" + i,
                            fn: function() {
                              return [
                                _c(
                                  "div",
                                  { key: i, staticClass: "table-actions" },
                                  [
                                    _c(
                                      "BaseButton",
                                      {
                                        attrs: {
                                          variation: "secondary",
                                          size: "s"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.editUser(user.id)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(" Editar "),
                                        _c("font-awesome-icon", {
                                          attrs: {
                                            size: "xs",
                                            icon: ["fas", "pen"]
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "BaseButton",
                                      {
                                        attrs: { size: "s" },
                                        on: {
                                          click: function($event) {
                                            return _vm.openUser(user.id)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(" Abrir "),
                                        _c("font-awesome-icon", {
                                          attrs: {
                                            size: "xs",
                                            icon: ["fas", "chevron-right"]
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          }
                        })
                      ],
                      null,
                      true
                    )
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm.modalOpen
        ? _c(
            "BaseModal",
            {
              attrs: {
                size: "l",
                title: "Perfil de cliente",
                "header-visible": true
              },
              on: { close: _vm.closeModal }
            },
            [
              _c("UserProfileCreation", {
                attrs: {
                  "user-id": _vm.userSelected ? _vm.userSelected.id : undefined
                },
                on: { close: _vm.closeModal }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }