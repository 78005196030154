<template>
  <div class="UserProfileStatement">
    <font-awesome-icon
      v-if="icon"
      class="check"
      :icon="icon"
    />

    <p
      v-if="statement"
      class="statement"
      v-html="statement"
    />

    <p
      class="answer"
      v-html="answer"
    />
  </div>
</template>

<script>
export default {
  props: {
    statement: {
      type: String,
      default: undefined,
    },

    answer: {
      type: String,
      default: undefined,
    },

    icon: {
      type: Array,
      default: undefined,
    },
  },
};
</script>

<style lang="scss" scoped>
.UserProfileStatement{
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  padding: $spacing-xs;
  gap: $spacing-xs;

  .check{
    color: $color-primary-lighter;
    align-self: center;
  }

  .statement{
    font-weight: 500;
  }

  ::v-deep {
     strong, b{
      font-weight: 500;
      color: $color-primary-dark;
    }

    b{
      text-transform: lowercase;
    }
  }
}
</style>
