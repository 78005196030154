var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "UserProfileCreation",
      on: {
        input: function($event) {
          return _vm.validation($event)
        }
      }
    },
    [
      !_vm.isLoading
        ? _c(
            "div",
            { staticClass: "block-content" },
            [
              _c("TransitionSlide", [
                _c(
                  "div",
                  {
                    staticClass: "template window-fit",
                    class: [_vm.blockTemplate]
                  },
                  _vm._l(_vm.questions, function(question, i) {
                    return _c(
                      "BlockQuestion",
                      {
                        key: i,
                        attrs: {
                          question: question,
                          value: _vm.getAnswer(question.id, question.key),
                          errors: _vm.errors,
                          temporary: _vm.isTemporaryAnswer(question.id),
                          "allow-modify-answers": true
                        },
                        on: {
                          "answer-selected": _vm.answerSelected,
                          "force-redirect": _vm.submitBlock
                        }
                      },
                      [
                        _c("ValidationError", {
                          attrs: { errors: _vm.errors, name: question.key }
                        })
                      ],
                      1
                    )
                  }),
                  1
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _c(
        "TransitionFade",
        [
          _vm.currentBlock && _vm.blocks
            ? _c("BlockPagination", {
                attrs: { blocks: _vm.blocks, "block-id": _vm.currentBlock.id },
                on: {
                  "change-block": _vm.changeBlock,
                  "submit-block": _vm.submitBlock
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }