var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Programs" },
    _vm._l(_vm.programs, function(program) {
      return _c(
        "div",
        {
          key: program.id,
          staticClass: "card",
          style: { "background-image": "url('" + program.image + "')" }
        },
        [
          _c("div", { staticClass: "text-container" }, [
            _c("div", { staticClass: "button" }, [
              program.id !== 3
                ? _c(
                    "div",
                    [
                      _c(
                        "BaseButton",
                        {
                          staticClass: "BaseButton",
                          attrs: { size: "l", variation: "secondary" },
                          on: {
                            click: function($event) {
                              return _vm.goTo(program.id, program.name)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t(program.translate)) + " ")]
                      )
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c(
                        "BaseButton",
                        {
                          staticClass: "BaseButton",
                          attrs: { size: "l", variation: "secondary" },
                          on: { click: _vm.goToProgramsNatura }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t(program.translate)) + " ")]
                      )
                    ],
                    1
                  )
            ])
          ])
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }