import { render, staticRenderFns } from "./UserProfile.vue?vue&type=template&id=6d3d3e57&scoped=true&"
import script from "./UserProfile.vue?vue&type=script&lang=js&"
export * from "./UserProfile.vue?vue&type=script&lang=js&"
import style0 from "./UserProfile.vue?vue&type=style&index=0&id=6d3d3e57&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d3d3e57",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/gestionetmultimedia/Documents/proyect_fronted/ortxadarfront/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6d3d3e57')) {
      api.createRecord('6d3d3e57', component.options)
    } else {
      api.reload('6d3d3e57', component.options)
    }
    module.hot.accept("./UserProfile.vue?vue&type=template&id=6d3d3e57&scoped=true&", function () {
      api.rerender('6d3d3e57', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/users/UserProfile.vue"
export default component.exports