var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ClientsView" },
    [
      _c("EmptySubHeader"),
      _c("h1", [_vm._v("Clientes")]),
      _c(
        "div",
        { staticClass: "content window-fit" },
        [
          _c(
            "TransitionFade",
            [
              _c("UserProfile", {
                key: _vm.getForcedReloading,
                attrs: { user: _vm.user }
              })
            ],
            1
          ),
          _c(
            "TransitionFade",
            [
              _c("Users", {
                key: _vm.getForcedReloading,
                attrs: { items: _vm.clients },
                on: { "open-user": _vm.openUser }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }