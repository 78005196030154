<template>
  <div class="ClientsView">
    <EmptySubHeader />
    <h1>Clientes</h1>
    <div class="content window-fit">
      <TransitionFade>
        <UserProfile
          :key="getForcedReloading"
          :user="user"
        />
      </TransitionFade>

      <TransitionFade>
        <Users
          :key="getForcedReloading"
          :items="clients"
          @open-user="openUser"
        />
      </TransitionFade>
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';

import TransitionFade from '@/transitions/TransitionFade';

import EmptySubHeader from '@/components/subheader/EmptySubHeader';
import UserProfile from '@/components/users/UserProfile';
import Users from '@/components/users/Users';

export default {
  components: {
    TransitionFade,
    EmptySubHeader,
    UserProfile,
    Users,
  },

  // data() {
  //   return {
  //     clients: undefined,
  //   };
  // },

  computed: {
    ...get('authModule', ['getUser']),
    ...get('reportsModule', ['getUsers']),
    ...get('profileModule', ['getForcedReloading']),

    user() {
      return this.getUser();
    },

    profile() {
      return this.user?.profile;
    },

    clients() {
      return this.getUsers();
    },

    isConsultant() {
      return this.user?.profile?.TYPE === 2;
    },
  },

  watch: {
    async getForcedReloading() {
      await this.$store.dispatch('authModule/fetchUser');
      await this.$store.dispatch('reportsModule/fetchClients', this.user.id);
    },
  },

  async created() {
    await this.$store.dispatch('authModule/fetchUser');
    if (this.isConsultant) {
      await this.$store.dispatch('reportsModule/fetchClients', this.user.id);
    } else {
      this.$router.push({ name: 'Reports' });
    }
  },

  methods: {
    async openUser(userId) {
      this.$router.push({ name: 'Reports', params: { userId } });
    },
  },
};
</script>

<style lang="scss" scoped>
.ClientsView{
  .content{
    padding: $spacing-m;
  }
}
</style>
