<template>
  <form
    class="UserProfileCreation"
    @input="validation($event)"
  >
    <div
      v-if="!isLoading"
      class="block-content"
    >
      <TransitionSlide>
        <div
          class="template window-fit"
          :class="[blockTemplate]"
        >
          <BlockQuestion
            v-for="(question, i) in questions"
            :key="i"
            :question="question"
            :value="getAnswer(question.id, question.key)"
            :errors="errors"
            :temporary="isTemporaryAnswer(question.id)"
            :allow-modify-answers="true"
            @answer-selected="answerSelected"
            @force-redirect="submitBlock"
          >
            <ValidationError
              :errors="errors"
              :name="question.key"
            />
          </BlockQuestion>
        </div>
      </TransitionSlide>
    </div>

    <TransitionFade>
      <BlockPagination
        v-if="currentBlock && blocks"
        :blocks="blocks"
        :block-id="currentBlock.id"
        @change-block="changeBlock"
        @submit-block="submitBlock"
      />
    </TransitionFade>
  </form>
</template>

<script>
import TransitionSlide from '@/transitions/TransitionSlide';
import TransitionFade from '@/transitions/TransitionFade';

import { get } from 'vuex-pathify';
import BlockQuestion from '@/components/blocks/BlockQuestion';
import BlockPagination from '@/components/blocks/BlockPagination';
import ValidationError from '@/components/common/ValidationError';

import ValidationMixin from '@/mixins/ValidationMixin';

export default {
  components: {
    TransitionSlide,
    TransitionFade,
    BlockQuestion,
    BlockPagination,
    ValidationError,
  },

  mixins: [ValidationMixin],

  props: {
    userId: { // change by userId
      type: Number,
      default: undefined,
    },
  },

  data() {
    return {
      newAnswers: [],
      currentBlock: undefined,
    };
  },

  computed: {
    ...get('authModule', ['getUser']),
    ...get('profileModule', [
      'isLoading',
      'getAnswers',
      'getBlocks',
      'getBlock',
      'getAnswerByQuestion',
      'getBlockQuestionsAnswer',
      'getAvailableQuestions',
    ]),

    blocks() {
      return this.getBlocks();
    },

    blockTemplate() {
      return this.currentBlock?.template;
    },

    questions() {
      if (!this.currentBlock) return undefined;
      return this.getAvailableQuestions(this.currentBlock.questions);
    },
  },

  async created() {
    console.log(this.userId);

    // todo get questions en funcion del tipo de usuario
    if (this.userId === this.getUser().id) {
      await this.$store.dispatch('profileModule/fetchProfileQuestions');
    } else {
      await this.$store.dispatch('profileModule/fetchClientProfileQuestions');
    }
    await this.$store.dispatch('profileModule/fetchAnswers', this.userId);

    if (!this.userId) {
      this.answerSelected({ questionId: null, questionKey: 'TYPE', answer: 1 });
    }

    this.currentBlock = this.blocks[0];
  },

  methods: {
    getAnswer(questionId, questionKey) {
      const answer = this.getAnswerByQuestion(questionId, questionKey);
      // console.log('answer', answer);
      return answer?.answer;
    },

    async answerSelected({ questionId, questionKey, answer }) {
      this.addAnswerToNewAnswers({ questionId, questionKey, answer });

      await this.$store.dispatch('profileModule/saveAnswer', { questionId, questionKey, answer });
    },

    addAnswerToNewAnswers(newAnswer) {
      console.log('newAnswer', newAnswer);
      const answers = this.newAnswers.filter(answer => answer.questionId !== newAnswer.questionId);
      this.newAnswers = [...answers, newAnswer];
    },

    async changeBlock(nextBlock) {
      if (nextBlock) {
        this.currentBlock = this.getBlock(nextBlock);
      } else {
        if (this.newAnswers.length > 0) {
          const answers = this.getAnswers();
          await this.send(answers);
        }

        this.$toast.success((this.userId) ? 'perfil modificado correctamente!' : 'perfil creado correctamente!');
        // todo load new user

        this.$emit('close');
      }
    },

    async submitBlock(nextBlock) {
      if (this.ckeckAllBlockQuestions()) {
        // if (this.user) {
        // await this.send(this.newAnswers);
        // this.newAnswers = [];
        // }

        this.changeBlock(nextBlock);
      }
    },

    ckeckAllBlockQuestions() {
      const blockQuestionsAnswer = this.getBlockQuestionsAnswer(this.currentBlock.id);
      this.errors = [];
      const validations = blockQuestionsAnswer.map((questionAnswer) => {
        if (!questionAnswer?.validations) return true;

        const valids = questionAnswer?.validations.map(validation => this.checkSpecificValidation(validation, questionAnswer));
        return valids.every(valid => valid);
      });

      return validations.every(validation => validation);
    },

    async send(answers) {
      await this.$store.dispatch('profileModule/sendClientAnswers', {
        answers,
        clientId: this.userId,
      });
    },

    isTemporaryAnswer(questionId) {
      return this.newAnswers.some(answer => answer.questionId === questionId);
    },
  },
};
</script>

<style lang="scss" scoped>
.UserProfileCreation{
  display: grid;
  grid-template-rows: 1fr auto;
  background: #fff;
  min-height: 500px;

  .block-content{
    flex: 1;
    display: grid;
    align-content: start;
    padding: $spacing-l;
  }

  .template{
    padding: $spacing-m;
    flex: 1;
    display: grid;
    gap: $spacing-m;
    align-content: flex-start;
  }
}
</style>
