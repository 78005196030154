<template>
  <div class="Programs">
    <div
      v-for="program in programs"
      :key="program.id"
      class="card"
      :style="{ 'background-image': `url('${program.image}')` }"
    >
      <div class="text-container">
        <div
          class="button"
        >
          <div v-if="program.id !== 3">
            <BaseButton
              size="l"
              variation="secondary"
              class="BaseButton"
              @click="goTo(program.id, program.name)"
            >
              {{ $t(program.translate) }}
            </BaseButton>
          </div>
          <div v-else>
            <BaseButton
              size="l"
              variation="secondary"
              class="BaseButton"
              @click="goToProgramsNatura"
            >
              {{ $t(program.translate) }}
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';

import natura from '@/assets/images/natura.jpg';
import azterkosta from '@/assets/images/azterkosta.jpg';
import ibaialde from '@/assets/images/ibaialde.jpg';

export default {
  data() {
    return {
      programs: [
        {
          id: 1, translate: 'TITLE.PROGRAM.AZTERKOSTA', name: 'Azterkosta', description: 'Es un hecho establecido hace dem un sitio mientras que mira su diseño. ', image: azterkosta,
        },
        {
          id: 2, translate: 'TITLE.PROGRAM.IBAIALDE', name: 'Ibaialde', description: 'Es tiempo que un lector se distraerá con el contenido del te un sitio mientras que mira su diseño. ', image: ibaialde,
        },
        {
          id: 3, translate: 'TITLE.PROGRAM.NATURA', name: 'Natura', description: 'Es un hecho establecido hace demasiado tiempo que un lector se distraero.', image: natura,
        },
      ],
    };
  },

  computed: {
    ...get('authModule', ['getUser']),

    user() {
      return this.getUser();
    },
  },


  methods: {
    goTo(programId, name) {
      window.scrollTo(0, 0);
      this.$router.push({ name: 'ProgramIntro', params: { programId, name } });
    },

    goToProgramsNatura() {
      window.scrollTo(0, 0);
      this.$router.push({ name: 'ProgramsNatura' });
    },
  },

};
</script>

<style lang="scss" scoped>
.Programs {
  margin: 5rem 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  width: 95%;

  .card {
    transition: all 0.25s;
    transition: transform 2s;
    box-shadow: $shadow-elevation-2;
    border-radius: 10px;
    background-size: cover;
    .text-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 20rem;
      transition: transform 1s;

      .button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: -3rem;
        background-size: cover;
        width: 100%;
        .BaseButton {
          height: 4rem;
          min-width: 16rem;
          font-weight: 700;
        }
      }
    }
  }
  @media #{$breakpoint-md-max} {
    .card {
      width: 100%;
      margin: 0;
      border-radius: 0;
      height: 20rem;
      margin-bottom: 3rem;
       .text-container {
         height: 20rem;
       }
    }
  }

}
</style>
