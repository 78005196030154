<template>
  <div class="UserProfile">
    <div class="company">
      <TransitionFade>
        <div
          v-if="profile"
          class="image"
        >
          <font-awesome-icon
            class="arrow"
            :icon="['far', 'building']"
          />
        </div>
      </TransitionFade>

      <TransitionSlide side="left">
        <div
          v-if="profile"
          class="content"
        >
          <div class="main-content">
            <span class="company-name">{{ profile.NAME }}</span>
            <span class="nif">{{ profile.NIF }}</span>
            <div>
              <BaseButton
                variation="secondary"
                class="edit-profile-button"
                size="s"
                @click="openModal"
              >
                Editar
                <font-awesome-icon
                  :icon="['fas', 'pen']"
                />
              </BaseButton>
            </div>
          </div>

          <p class="dir">
            {{ profile.DIR }}
          </p>
        </div>
      </TransitionSlide>
    </div>


    <div class="info">
      <TransitionSlide side="bottom">
        <div
          v-if="profile"
          class="main"
        >
          <font-awesome-icon
            class="coins-image"
            :icon="['fas', 'coins']"
          />

          <div class="activity">
            <p v-html="`${$t('QUESTION.ACT.LABEL')}:`" />
            <p v-html="$t(`QUESTION.ACT.CHOICE.${profile.ACT}`)" />
          </div>

          <UserProfileStatement
            :icon="['fas','caret-right']"
            :answer="$t('RESULT.EMP.LABEL',[$t(`QUESTION.PEMP.CHOICE.${profile.EMP}`)])"
          />

          <UserProfileStatement
            :icon="['fas','caret-right']"
            :answer="$t('RESULT.BUS.LABEL', [$t(`QUESTION.PBUS.CHOICE.${profile.BUS}`)])"
          />
          <UserProfileStatement
            :icon="['fas','caret-right']"
            :answer="$t('RESULT.BAL.LABEL', [$t(`QUESTION.PBAL.CHOICE.${profile.BAL}`)])"
          />
        </div>
      </TransitionSlide>

      <TransitionSlide side="bottom">
        <div
          v-if="profile"
          class="sustainability"
        >
          <h2 class="title">
            Sostenibilidad
          </h2>

          <font-awesome-icon
            class="eco-image"
            :icon="['fas', 'leaf']"
          />

          <UserProfileStatement
            v-if="profile.SOS1 === 1"
            :icon="['fas','check']"
            :answer="$tc('RESULT.SOS2.LABEL', profile.SOS2, [profile.SOS2])"
          />

          <UserProfileStatement
            v-if="profile.SOS1 === 1"
            :icon="['fas','check']"
            :answer="$t('RESULT.VOL.LABEL', [$t(`QUESTION.VOL.CHOICE.${profile.VOL}`)])"
          />

          <UserProfileStatement
            v-if="profile.SOS1 === 1"
            :icon="['fas','check']"
            :answer="$t('RESULT.INF.LABEL',[INFAnswer])"
          />
        </div>
      </TransitionSlide>
    </div>

    <BaseModal
      v-if="modalOpen"
      size="l"
      title="Perfil"
      :header-visible="true"
      @close="closeModal"
    >
      <UserProfileCreation
        :user-id="user.id"
        @close="closeModal"
      />
    </BaseModal>
  </div>
</template>

<script>

import UserProfileStatement from '@/components/users/UserProfileStatement';
import UserProfileCreation from '@/components/users/UserProfileCreation';
import TransitionFade from '@/transitions/TransitionFade';
import TransitionSlide from '@/transitions/TransitionSlide';

export default {
  components: {
    UserProfileStatement,
    UserProfileCreation,
    TransitionFade,
    TransitionSlide,
  },

  props: {
    user: {
      type: Object,
      default: undefined,
    },
  },

  data() {
    return {
      modalOpen: false,
    };
  },

  computed: {
    profile() {
      return this.user?.profile;
    },

    INFAnswer() {
      if (!this.profile) return undefined;
      return (this.profile.INF?.subvalue) ? this.profile.INF.subvalue : this.$t(`QUESTION.INF.CHOICE.${this.profile.INF.value}`);
    },
  },

  mounted() {
    console.log('profile', this.profile);
  },

  methods: {
    openModal() {
      this.modalOpen = true;
    },

    closeModal() {
      this.modalOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.UserProfile{
  margin-bottom: $spacing-xl;

  .company{
    display: grid;
    grid-template-columns: 100px 1fr;
    margin-bottom: $spacing-l;

    &:empty{
      @include empty-content(80px);
    }

    .image{
      background:  $color-primary-lightest;
      color: $color-primary-dark;
      font-size: $font-size-4xl;
      display: grid;
      place-content: center;
    }

    .content{
      display: grid;
      gap: $spacing-xs;
      padding: $spacing-m;
      background: #fff;

      .main-content{
        display: grid;
        align-items: baseline;
        grid-template-columns: auto 1fr auto;

        .edit-profile-button{
          padding-right: $spacing-m;
          margin-left: $spacing-m;
          // right: -.5rem;
          // top: -.5rem;
        }
      }

      .company-name{
        text-transform: uppercase;
        font-weight: 500;
        font-size: $font-size-l;
      }

      .nif{
        padding: $spacing-xs;
        font-size: $font-size-s;
      }
    }
  }

  .info{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(670px, 1fr));
    gap: $spacing-m;

    &:empty{
      @include empty-content(250px);
    }

    :is(.main, .sustainability){
      padding: $spacing-m;
    }

    .title{
      font-weight: 500;
      text-transform: uppercase;
      padding: $spacing-xs;
      margin-bottom: $spacing-s;
      border-bottom: 1px solid;
    }

    .main{
      background: #fff;
      position: relative;
      z-index: 1;
      overflow: hidden;

      .activity{
        padding: $spacing-s;
        & > p:first-child {
          font-weight: 500;
          margin-bottom: $spacing-2xs;
        }
      }
    }

    .sustainability{
      background: $color-primary-lightest;
      min-height: 150px;
      position: relative;
      z-index: 1;
      overflow: hidden;
    }

    .coins-image,
    .eco-image{
      position: absolute;
      right: $spacing-m;
      bottom: -1rem;
      font-size: 8rem;
      z-index: -1;
    }

    .coins-image{
      color: $color-neutral-light;
    }

    .eco-image{
      color: $color-primary-lighter;
    }
  }
}
</style>
