var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.user && _vm.user.profile === 2
    ? _c(
        "div",
        { staticClass: "DashboardView" },
        [
          _c("EmptySubHeader", { staticClass: "subheader" }),
          _c("h1", { staticClass: "title" }, [
            _vm._v(" " + _vm._s(_vm.$t("SELECT.TITLE.PROGRAM")) + " ")
          ]),
          !_vm.isLoading
            ? _c("div", { staticClass: "content" }, [_c("Programs")], 1)
            : _c("div", [_c("BaseSpinner")], 1),
          _vm._m(0)
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo-aztertu" }, [
      _c(
        "a",
        {
          attrs: {
            href:
              "https://www.euskadi.eus/informacion/programa-aztertu/web01-a2inghez/es/",
            target: "_blank"
          }
        },
        [
          _c("img", {
            staticClass: "logo",
            attrs: { src: require("@/assets/images/aztertu_completo.png") }
          })
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }