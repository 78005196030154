var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "UserProfileStatement" },
    [
      _vm.icon
        ? _c("font-awesome-icon", {
            staticClass: "check",
            attrs: { icon: _vm.icon }
          })
        : _vm._e(),
      _vm.statement
        ? _c("p", {
            staticClass: "statement",
            domProps: { innerHTML: _vm._s(_vm.statement) }
          })
        : _vm._e(),
      _c("p", {
        staticClass: "answer",
        domProps: { innerHTML: _vm._s(_vm.answer) }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }